<template>
    <grid-view 
        title="Уведомления"
        store-module="notification"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Уведомление', value: 'text' }
        ]"
    >
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'notificationIndex',
    components: { GridView }, 
    data() {
        return {}
    },
    methods: {}
}
</script>